<div *ngIf="isLoading" class="loading-shade">
  <mat-spinner></mat-spinner>
</div>

<section ngClass.gt-xs="large mat-elevation-z5">
  <header fxLayout fxLayoutAlign="center center" fxLayoutGap="16px">
    <mat-icon svgIcon="logo" color="primary"></mat-icon>
    <h1 class="mat-display-2">Woozgo</h1>
  </header>

  <div
    class="subtitle"
    fxLayout
    fxLayoutAlign="start center"
    fxLayoutGap="16px"
  >
    <h2 fxFlex>Back-Office</h2>
    <mat-divider fxFlex="24px" fxFlex.gt-xs="96px"></mat-divider>
  </div>

  <form [formGroup]="form" (ngSubmit)="signIn()">
    <mat-form-field>
      <mat-label>login</mat-label>
      <input matInput formControlName="login" placeholder="Put your login" />
      <mat-error *ngIf="form.hasError('required', 'login')"
        >Login required</mat-error
      >
    </mat-form-field>
    <mat-form-field>
      <mat-label>Password</mat-label>
      <input
        matInput
        formControlName="password"
        placeholder="Put your password"
        type="password"
      />
      <mat-error *ngIf="form.hasError('required', 'password')"
        >Password required</mat-error
      >
    </mat-form-field>
    <mat-checkbox formControlName="rememberMe">Remember Me</mat-checkbox>
    <button mat-raised-button color="primary" [disabled]="form.invalid">
      <mat-icon matPrefix>{{ form.invalid ? "lock" : "lock_open" }}</mat-icon>
      Sign In
    </button>
  </form>
  <footer fxShow fxHide.gt-xs>
    <ng-container *ngTemplateOutlet="footer"></ng-container>
  </footer>
</section>
<footer fxHide fxShow.gt-xs>
  <ng-container *ngTemplateOutlet="footer"></ng-container>
</footer>

<ng-template #footer>
  <p>by Digital Virgo</p>
  <wzg-dark-mode-toggle></wzg-dark-mode-toggle>
</ng-template>
