import {
  animate,
  animateChild,
  group,
  query,
  style,
  transition,
  trigger,
} from '@angular/animations';

export const slidingRow = trigger('slidingRow', [
  transition(':enter', [
    style({
      opacity: 0,
      transform: 'translateX(-24px)',
    }),
    animate('300ms ease'),
  ]),
]);

export const fadeInOut = trigger('fadeInOut', [
  transition(':enter', [
    style({ opacity: 0, transform: 'scale(.94)' }),
    animate('300ms ease'),
  ]),
  transition(
    ':leave',
    animate(
      '300ms ease',
      style({ opacity: 0, transform: 'scale(.94)', width: 0, padding: 0 })
    )
  ),
]);

export const routeSwap = trigger('routeSwap', [
  transition(':enter', [
    query(
      ':enter',
      [
        style({ opacity: '0', transform: 'scale(.95)' }),
        animate('300ms ease-out'),
        animateChild(),
      ],
      { optional: true }
    ),
  ]),
  transition(':decrement', [
    style({
      position: 'relative',
      overflow: 'hidden',
    }),
    group([
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }),
      ]),
      query(':enter', [style({ top: '-100%' })]),
      query(':leave', animateChild()),
      group([
        query(':leave', [animate('300ms ease-out', style({ top: '100%' }))]),
        query(':enter', [animate('300ms ease-out', style({ top: '0%' }))]),
      ]),
      query(':enter', animateChild()),
    ]),
  ]),
  transition(':increment', [
    style({
      position: 'relative',
      overflow: 'hidden',
    }),
    group([
      query(':enter, :leave', [
        style({
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }),
      ]),
      query(':enter', [style({ top: '100%' })]),
      query(':leave', animateChild()),
      group([
        query(':leave', [animate('300ms ease-out', style({ top: '-100%' }))]),
        query(':enter', [animate('300ms ease-out', style({ top: '0%' }))]),
      ]),
      query(':enter', animateChild()),
    ]),
  ]),
]);
