import { Inject, Injectable, InjectionToken } from '@angular/core';

export const BROWSER_STORAGE = new InjectionToken<Storage>('Browser Storage', {
  providedIn: 'root',
  factory: () => localStorage,
});

@Injectable({
  providedIn: 'root',
})
export class BrowserStorageService {
  constructor(@Inject(BROWSER_STORAGE) public storage: Storage) {}

  get<T>(key: string): T | null {
    const storedValue = this.storage.getItem(key);

    try {
      return storedValue ? JSON.parse(storedValue) : null;
    } catch {
      this.remove(key);
      return null;
    }
  }

  set<T>(key: string, value: NonNullable<T>): void {
    this.storage.setItem(key, JSON.stringify(value));
  }

  remove(key: string): void {
    this.storage.removeItem(key);
  }

  clear(): void {
    this.storage.clear();
  }
}
