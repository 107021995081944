import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { map, Observable, take } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class LoggedInGuard implements CanActivate {
  signInUrlTree = this.router.parseUrl('/sign-in');

  constructor(private router: Router, private auth: AuthService) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.auth.isAuthenticated.pipe(
      take(1),
      map((isAuthenticated) => isAuthenticated || this.signInUrlTree)
    );
  }
}
