<mat-sidenav-container>
  <mat-sidenav
    fixedInViewport
    fixedTopGap="0"
    fixedBottomGap="0"
    [mode]="(mediumScreenState | async)?.matches ? 'over' : 'side'"
    [opened]="!(mediumScreenState | async)?.matches"
    class="mat-elevation-z4 dark"
    #menu
  >
    <mat-toolbar color="primary">
      <mat-icon class="logo" svgIcon="logo"></mat-icon>
      <span class="site-name">Woozgo</span>
    </mat-toolbar>
    <wzg-main-menu></wzg-main-menu>
  </mat-sidenav>
  <mat-sidenav-content>
    <mat-toolbar class="mat-elevation-z3">
      <button mat-icon-button (click)="menu.toggle()">
        <mat-icon>menu</mat-icon>
      </button>
      <h1 *ngIf="getRouteTilte() as title">{{ title }}</h1>
      <span class="separator"></span>
      <wzg-dark-mode-toggle></wzg-dark-mode-toggle>
      <mat-divider vertical></mat-divider>
      <wzg-user-menu></wzg-user-menu>
    </mat-toolbar>
    <main [@routeSwap]="getRouteAnimationData()">
      <router-outlet #outlet="outlet"></router-outlet>
    </main>
  </mat-sidenav-content>
</mat-sidenav-container>
