import { CommonModule, DOCUMENT } from '@angular/common';
import { Inject, NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { DarkModeToggleComponent } from './dark-mode-toggle/dark-mode-toggle.component';
import { DarkModeService } from './dark-mode.service';

@NgModule({
  declarations: [DarkModeToggleComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule],
  exports: [DarkModeToggleComponent],
  providers: [DarkModeService],
})
export class DarkModeModule {
  constructor(darkMode: DarkModeService, @Inject(DOCUMENT) document: Document) {
    const darkClass = 'dark';
    const bodyClassList = document.body.classList;
    darkMode.activationChanges.subscribe((activated) => {
      activated
        ? bodyClassList.add(darkClass)
        : bodyClassList.remove(darkClass);
    });
  }
}
