import { NgModule } from '@angular/core';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { Router, RouterModule } from '@angular/router';
import {
  ApolloClientOptions,
  ApolloLink,
  InMemoryCache,
  split,
} from '@apollo/client/core';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { createClient } from 'graphql-ws';
import { environment } from '../environments/environment';
import { TokenService } from './core/token.service';

export function createApollo(
  httpLink: HttpLink,
  token: TokenService,
  snackBar: MatSnackBar,
  router: Router
): ApolloClientOptions<any> {
  const http = httpLink.create({
    uri: environment.api.http,
    withCredentials: true,
  });
  const ws = new GraphQLWsLink(
    createClient({
      url: environment.api.ws,
      connectionParams: () => {
        const value = token.get();
        if (value) {
          return {
            Authorization: `Bearer ${value}`,
          };
        }
        return {};
      },
    })
  );

  const authLink = setContext(() => {
    const value = token.get();
    if (value) {
      return {
        headers: {
          Authorization: `Bearer ${value}`,
        },
      };
    }
    return {};
  });
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, extensions }) => {
        if (extensions['code'] === 'UNAUTHENTICATED') {
          token.remove();
          router.navigateByUrl('/sign-in');
        }

        snackBar.open(message, 'OK', {
          panelClass: 'error-snack-bar',
        });
      });
    }

    if (networkError) {
      snackBar.open(networkError.message, 'OK', {
        panelClass: 'error-snack-bar',
      });
    }
  });
  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition?.kind === 'OperationDefinition' &&
        definition?.operation === 'subscription'
      );
    },
    ws,
    http
  );

  return {
    link: ApolloLink.from([authLink, errorLink, splitLink]),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  exports: [ApolloModule],
  imports: [MatSnackBarModule, RouterModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, TokenService, MatSnackBar, Router],
    },
  ],
})
export class GraphQLModule {}
