<ng-container *ngIf="user$ | async as user">
  <button mat-button [matMenuTriggerFor]="menu" aria-label="User actions menu">
    <span fxHide fxShow.gt-xs class="name">{{ user.name }}</span>
    <mat-icon>account_circle</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button mat-menu-item (click)="logout()">
      <mat-icon>logout</mat-icon>
      <span>Logout</span>
    </button>
  </mat-menu>
</ng-container>
