import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BrowserStorageService } from './browser-storage.service';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  get token(): Observable<string | null> {
    return this.tokenSubject.asObservable();
  }
  private tokenSubject = new BehaviorSubject<string | null>(
    this.storage.get('token')
  );

  constructor(private storage: BrowserStorageService) {}

  get(): string | null {
    return this.tokenSubject.value;
  }

  set(token: string): void {
    this.tokenSubject.next(token);
    this.storage.remove('token');
  }

  persist(token: string): void {
    this.tokenSubject.next(token);
    this.storage.set('token', token);
  }

  remove(): void {
    this.tokenSubject.next(null);
    this.storage.remove('token');
  }
}
